<template>
  <div class="pa-10" v-if="accessTokenLifetime && hasPermission('serversettings')">
    <div>
      <h1>Server Settings</h1>
    </div>
    <p><strong>Access Token Lifetime (seconds):</strong> {{ accessTokenLifetime }}</p>
    <v-text-field
        label="Access Token Lifetime (seconds)"
        hide-details="auto"
        type="number"
        v-model.number="accessTokenLifetimeNew"
    ></v-text-field>
    <br>
    <v-btn
        depressed
        color="primary"
        @click="sendMessage"
    >Send</v-btn>
  </div>
</template>

<script>
import notify from "devextreme/ui/notify";
import axios from "axios";
import accountService from "@/services/account.service";

export default {
  data() {
    return {
      accessTokenLifetime: null,
      accessTokenLifetimeNew: null
    }
  },
  created() {
    if(this.hasPermission('serversettings')) {
      axios.get('api/ServerSettings').then(r => {
        this.accessTokenLifetime = r.data;
        this.accessTokenLifetimeNew = this.accessTokenLifetime;
      });
    }
  },
  methods: {
    hasPermission(permissionValue) {
      return accountService.userHasPermission(permissionValue);
    },
    sendMessage() {
      axios.post('api/ServerSettings', {
        accessTokenLifetime: this.accessTokenLifetimeNew
      }).then(r => {
        this.accessTokenLifetime = this.accessTokenLifetimeNew;
        notify({
          message: "Updated",
          position: {
            at: "top",
            offset: "0 40"
          }
        }, "success", 5000);
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
